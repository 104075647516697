<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>市场数据分析</a-breadcrumb-item>
            <a-breadcrumb-item>市场渠道分析</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                </div>
                <div class="search">
                    <a-form layout='inline'>
                    <a-form-item>
                        <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                        <!-- <a-radio-button value="day">
                            日
                        </a-radio-button> -->
                        <a-radio-button value="week">
                            周
                        </a-radio-button>
                        <a-radio-button value="month">
                            月
                        </a-radio-button>
                        <a-radio-button value="year">
                            年
                        </a-radio-button>
                        </a-radio-group>
                    </a-form-item>

                    <a-form-item>
                      <a-radio-group :value="paramsData.contract_type">
                        <a-radio-button @click="handleTypeButChange('-1')" value="-1">
                            付费试听
                        </a-radio-button>
                        <a-radio-button @click="handleTypeButChange('1')" value="1">
                            新签
                        </a-radio-button>
                        <a-radio-button @click="handleTypeButChange('2')" value="2">
                            续费
                        </a-radio-button>
                        <a-radio-button @click="handleTypeButChange('3')" value="3">
                            转介绍
                        </a-radio-button>
                      </a-radio-group>
                    </a-form-item>

                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>时间筛选</span>
                        </template>
                        <a-range-picker
                            style="width: 240px"
                            :ranges="rangesData"
                            v-model='monthvalue'
                            :allowClear="false"
                            format="YYYY-MM"
                            :mode="['month', 'month']"
                            @panelChange="handlePanelChange2"
                            @change="handleChange2"
                            />
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="toScreen" :loading="loading" type="primary">筛选</a-button>
                    </a-form-item>
                    <a-form-item>
                        <a-popover placement="bottom" trigger="click" v-model="visible">
                        <template slot="content">
                            <div class="more-search">
                            <a-form layout='inline'>
                                <a-form-item class="block-line" label="来源渠道">
                                  <a-tree-select
                                      v-model="paramsData.channel_id"
                                      :tree-data="parentChannels"
                                      tree-checkable
                                      allowClear
                                      treeNodeFilterProp="title"
                                      :maxTagCount='1'
                                      :maxTagTextLength='5'
                                      :dropdownStyle="{zIndex:4200,maxHeight:'400px'}"
                                      :show-checked-strategy="SHOW_ALL"
                                      placeholder="请选择来源渠道"
                                  />
                                </a-form-item>
                                <a-form-item class="block-line" label="所属校区">
                                  <a-select v-model="paramsData.studio_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' allowClear showSearch placeholder="请选择校区" :filterOption="filterOption">
                                      <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                                  </a-select>
                                </a-form-item>
                                <a-form-item class="block-line" label="市场归属">
                                    <a-select mode="multiple" :maxTagCount="1" :maxTagTextLength='5' v-model='paramsData.created_by' allowClear showSearch @search="handleSearch" placeholder="请选择市场归属" :filterOption="filterOption">
                                    <a-select-option v-for="(d, index) of staffList" :key="index" :value="d['employee_id']">{{ d['filter_name'] }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                                <a-form-item class="block-line" label="课程顾问">
                                    <a-select mode="multiple" :maxTagCount="1" :maxTagTextLength='5' v-model='paramsData.obj_id' allowClear showSearch @search="handleSearch" placeholder="请选择课程顾问" :filterOption="filterOption">
                                    <a-select-option v-for="(d, index) of staffList" :key="index" :value="d['employee_id']">{{ d['filter_name'] }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-form>
                            <div class="more-search__buttons">
                                <a-button type="dashed" @click="closeSearch">取消</a-button>
                                <a-button @click="reset">重置</a-button>
                                <a-button type="primary" @click="toScreen">搜索</a-button>
                            </div>
                            </div>
                        </template>
                        <a-button>更多<a-icon type="down" /></a-button>
                        </a-popover>
                    </a-form-item>
                    </a-form>
                </div>
            </div>   
            <div class="dashboard bk-group">
                <statistics :count='counts'/>
                <a-row class="mb16" :gutter="16">
                    <a-col :lg="24" :xl="24">
                        <cycle :loading='loading' title="渠道成交" :item ='leads_data' :start_date='paramsData.start_date' :end_date='paramsData.end_date'  />
                    </a-col>

                    <a-col :lg="24" :xl="24">
                      <div class="sign-table" style="background:#fff;">
                          <vue-element-loading :active="loadingCC" color="#00cca2" spinner="spinner"/>
                          <a-table class="sign-table-1" size="small" :pagination="false" :bordered='false' rowKey="channel_id"
                          :columns="contract_columns" :dataSource="contract_list" @expand='handleExpand'>
                            <template slot="index" slot-scope="text, record , index">
                              <span>{{(index+1)}}</span>
                            </template>
                            <template v-for="(it,j) in name_list" :slot="it.filed_name" slot-scope="text, record , index">
                              <div :key="j">
                                <span >{{text}}</span>
                                <br/>
                                <span style="color:rgba(69, 90, 100, 0.4)">({{record[`${it.filed_name}F`]}})</span>
                              </div>
                            </template>
                            <a-table class="sign-table-2" size="small" slot="expandedRowRender" slot-scope="text" :pagination="false" :bordered='false' rowKey="channel_id"
                            :columns="contract_columns" :dataSource="text.childList">
                            <template slot="index" slot-scope="text, record , index">
                              <span>{{(index+1)}}</span>
                            </template>
                            <template v-for="(it,j) in name_list" :slot="it.filed_name" slot-scope="text, record , index">
                              <div :key="j">
                                <span >{{text}}</span>
                                <br/>
                                <span style="color:rgba(69, 90, 100, 0.4)">({{record[`${it.filed_name}F`]}})</span>
                              </div>
                            </template>
                            </a-table>
                          </a-table>
                      </div>
                    </a-col>
                    
                    <a-col :lg="24" :xl="12">
                          <LRanking title="渠道大类金额" :rank_data="parent_channel" :loading="loadingC" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                        <LRanking title="细分渠道金额" :rank_data="sub_channel" :loading="loadingC" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                        <VErings :loading='loadingC' :item ='parent_channel' title="渠道大类比例" subtext='金额' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                        <VErings :loading='loadingC' :item ='sub_channel' title="细分渠道比例" subtext='金额' />
                    </a-col>
                </a-row>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import cycle from './cycle'
import { TreeSelect } from 'ant-design-vue'
const SHOW_ALL = TreeSelect.SHOW_ALL
import statistics from '@/views/bi/ranking/money/statistics'
export default {
  name: 'customer',
  components:{
    cycle,
    statistics
  },
  data() {
    return {
      SHOW_ALL,
      visible:false,
      loading:false,
      loadingC:false,
      loadingCC:false,
      leads_data:{},
      counts:[],
      studios:[],
      staffList:[],
      channelsId:[],
      parentChannels: [],
      parent_channel:{},
      sub_channel:{},
      paramsData:{
        obj_id:[],
        studio_id:undefined,
        contract_type:undefined,
        start_date:moment().startOf('quarter').format('YYYY-MM-DD'),
        end_date:moment().endOf('quarter').format('YYYY-MM-DD'),
        filter_type:'week'
      },
      monthvalue:[],
      rangesData:{
        '本月': [moment().startOf('month'),moment().endOf('month')],
        '上个月': [moment(moment().startOf('month')).add(-1, 'month'),moment(moment().endOf('month')).add(-1, 'month')],
        '本季度': [moment().startOf('quarter'),moment().endOf('quarter')],
        '上季度': [moment(moment().quarter(moment().quarter() - 1).startOf('quarter').valueOf()),moment(moment().quarter(moment().quarter() - 1).endOf('quarter').valueOf())],
        '今年': [moment().startOf('year'), moment().endOf('year')],
        '去年': [moment().year(moment().year() -1).startOf('year'), moment().year(moment().year() -1).endOf('year')]
      },
      contract_list:[],
      contract_columns:[],
      filedLength:0,
      contract_child_list:[],
      name_list:[],
    }
  },
  computed: {
      RelativeWidth() {
          let width = 850
          if(this.filedLength >10){
            width = 900 + ( this.filedLength - 10 ) * 130
          }
          return width
      }
  },
  created() {
    this.monthvalue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment().endOf('quarter')]
    this.toScreen()
    this.getStudio()
    this.getStaff()
    this.getSourceChannel()
  },

  methods: {
    moment,
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getList(obj){
      this.loading = true
      this.getAchievementChannel(obj)
      let res = await this.$store.dispatch('analysisCrmChannelCycleAction', obj)
      this.leads_data = res.data.cycle
      this.loading = false
    },
    async getAchievementChannel(obj){
      this.loadingC = true
      let res = await this.$store.dispatch('analysisCrmChannelAchievementChannelAction', obj)
      this.parent_channel = res.data.parent_channel
      this.sub_channel = res.data.sub_channel
      this.loadingC = false
    },
    async getContractCycle(obj) {
      this.loadingCC = true
      let res = await this.$store.dispatch('analysisCrmChannelContractCycleAction', obj)
      let contract_list = res.data.cycle_week
      contract_list.forEach(item=>{
        item.contract_child_list = []
        item.total = this.paramsData.filter_column == 'money'?this.filterNum(item.total):item.total
        item.average_transaction_value = this.paramsData.filter_column == 'money'?this.filterNum(item.average_transaction_value):item.average_transaction_value
        item.filed.forEach(it=>{
            item[it.filed_name] = this.paramsData.filter_column == 'money'?this.filterNum(it.filed_value):it.filed_value
            item[`${it.filed_name}F`] = it.filed_precent
        })
        let contract_child_list = item.sub_channel
        contract_child_list.forEach(it=>{
          it.total = this.paramsData.filter_column == 'money'?this.filterNum(it.total):it.total
          it.average_transaction_value = this.paramsData.filter_column == 'money'?this.filterNum(it.average_transaction_value):it.average_transaction_value
          it.filed.forEach(its=>{
              it[its.filed_name] = this.paramsData.filter_column == 'money'?this.filterNum(its.filed_value):its.filed_value
              it[`${its.filed_name}F`] = its.filed_precent
          })
        })
        item.contract_child_list = contract_child_list

      })
      let course_week = [
          { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
          { title: '渠道名称', width:'175px', dataIndex: 'channel_name',  key: 'channel_name'},
          { title: '合计', width:'120px', dataIndex: 'channel_total', key: 'channel_total',align:'right'},
          { title: '占比', width:'70px', dataIndex: 'channel_precent', key: 'channel_precent',align:'right'},
      ]
      if(contract_list[0]){
        this.name_list = contract_list[0].filed
        contract_list[0].filed.forEach(item=>{
            let obj = { title: item.filed_name, dataIndex: item.filed_name, key: item.filed_name,width:'80px',align:'right',scopedSlots: { customRender: item.filed_name }}
            course_week.push(obj)
        })
      }
      if(contract_list[0]){
        this.filedLength = contract_list[0].filed.length
      }else{
        this.filedLength = 0
      }
      this.contract_columns = course_week
      this.contract_list = contract_list
      console.log(this.contract_list,'this.contract_list')
      this.loadingCC = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getStaff(val){
      let obj = {q:val};
      let res = await this.$store.dispatch('searchAccountEmployeeAction', {params:obj})
      this.staffList = res.data
    },
    async getCount(obj){
      let res = await this.$store.dispatch('analysisCrmChannelCountAction', obj)
      this.counts = res.data
    },
    async getSourceChannel() {
      let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
      this.parentChannels = res.data
    },
    handleSearch(val){
      this.getStaff(val)
    },
    handleEmployee(value){
        this.paramsData.obj_id = value
    },
    handleChange(value){
      this.paramsData.studio_id = value
    },
    handleButChange(e){
      this.paramsData.filter_type = e.target.value
      this.toScreen()
    },
    handleTypeButChange(e){
      if(this.paramsData.contract_type == e){
        this.paramsData.contract_type = undefined
      }else{
        this.paramsData.contract_type = e
      }
      this.toScreen()
    },
    handleChange2(value) {
      this.paramsData.start_date = moment(value[0]).format('YYYY-MM-DD')
      this.paramsData.end_date = moment(value[1]).format('YYYY-MM-DD')
      this.monthvalue = value
      this.toScreen()
    },
    handlePanelChange2(value, mode) {
      this.paramsData.start_date = moment(value[0]).format('YYYY-MM-DD')
      this.paramsData.end_date = moment(value[1]).format('YYYY-MM-DD')
      this.monthvalue = value
      this.mode2 = [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]]
      this.toScreen()
    },
    
    toScreen(){
      let obj = {
        search:this.paramsData
      }
      this.getList(obj);
      this.getContractCycle(obj)
    },
    closeSearch() {
      this.visible = false
    },
    reset(){
      this.paramsData.channel_id = undefined
      this.channelsId = []
      this.paramsData.studio_id = undefined
      this.paramsData.created_by = undefined
      this.paramsData.obj_id = undefined
    },
    filterNum(val){
      val = val.toString().replace(/\$|\,/g,'');
      if(isNaN(val)) {
        val = "0";  
      } 
      let sign = (val == (val = Math.abs(val)));
      val = Math.floor(val*100+0.50000000001);
      let cents = val%100;
      val = Math.floor(val/100).toString();
      if(cents<10) {
        cents = "0" + cents
      }
      for (var i = 0; i < Math.floor((val.length-(1+i))/3); i++) {
          val = val.substring(0,val.length-(4*i+3))+',' + val.substring(val.length-(4*i+3));
      }

      return (((sign)?'':'-') + val + '.' + cents);
    },
    handleExpand(expanded,record){
      console.log(record)
      record.childList = record.contract_child_list
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="less">
  .sign-table{
    .sign-table-1{
      .ant-table-row{
        td:nth-child(3){
          background: rgb(229, 244, 254);
        }
        td:nth-child(4){
          background: rgb(229, 244, 254);
        }
        td:nth-child(5){
          background: rgb(229, 244, 254);
        }
      }
    }
    .sign-table-2{
      .ant-table-row{
        td:nth-child(2){
          background: rgb(229, 244, 254);
        }
        td:nth-child(3){
          background: rgb(229, 244, 254);
        }
        td:nth-child(4){
          background: rgb(229, 244, 254);
        }
        td:nth-child(5){
          background: #fff;
        }
      }
    }
  }
</style>

